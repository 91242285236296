import { device } from "@styles/device.styled";
import Mixin from "@styles/mixin";
import styled, { css } from "styled-components";

export const SearchWrapper = styled.div`
padding:0 2rem 1.6rem;
margin: 0 -2rem;
${Mixin.GridLayout({ gridDimension: '1fr 12.6rem', columnGap: '1rem', mobDimension: '1fr 3.93rem' })};
${({ theme: { JobSearch } }) =>
    JobSearch &&
    css`
      border-bottom: 0.1rem solid ${JobSearch.BdrColors.wrapper};
    
input{
  height: 3.5rem;
  padding: 0.6rem 1.2rem;
  font-size: ${JobSearch.FontSizes.title};
  color: ${JobSearch.Colors.wrapper};
}
button{
  border-radius: 0;
  text-transform: uppercase;
  font-size: ${JobSearch.FontSizes.title};
}
`}
`;
export const JobBtnWrap = styled.ul<{ isSecondaryBox?: boolean }>`
list-style: none;
margin: 0;
padding:${(props) => props.isSecondaryBox ? '0.8rem 0 0' : '0.8rem 0 0.4rem'} ;
> li{
  display: inline-block;
  vertical-align: top;
  margin: 0 0.16rem 0.4rem 0.16rem;
  a{
    ${({ theme: { JobSearch } }) =>
    JobSearch &&
    css`
    font-family: ${JobSearch?.FontFamily?.btnWrapper};
    color: ${JobSearch.Colors.btnWrapper};
    font-size: ${JobSearch.FontSizes.title};
    background-color: ${JobSearch.BgColors.btnWrapper};  
    
    padding: 0.7rem 0.6rem;
    text-align: center;
    border: 0.1rem solid ${JobSearch.BdrColors.btnWrapper};
    
    border-radius: 0.2rem;
    display: block;
    &:hover{
      text-decoration: none;
      color: ${JobSearch.Colors.btnWrapperHover};
    }
    &.active{
      background-color: ${JobSearch.Colors.btnWrapperActive};
      border: 0.1rem solid ${JobSearch.BdrColors.btnWrapperActive};
      color: ${JobSearch.Colors.btnWrapperActive};
    }
    `}
  }
}
`;
export const ToggleIcon = styled.span<{ isToggle?: boolean, isAccordionIcon?: boolean }>`
  width: ${(props) => props.isAccordionIcon ? '0.9rem' : '1.3rem'};    
  height: ${(props) => props.isAccordionIcon ? '0.9rem' : '1.4rem'};
  

  position: relative;  
  display: block;
  ${({ theme: { JobSearch },isAccordionIcon }) =>
    JobSearch &&
    css`
    border-color:${!isAccordionIcon && JobSearch.BdrColors.accordionBtn} ;
    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: ${isAccordionIcon ? JobSearch.BgColors.accordionBtn : JobSearch.BgColors.accordionBtnNot}; 
    }
    `}


&:before {
  width: 0.2rem;             
  height: 100%;          
  left: 50%;              
  top: 0;
  transform: translateX(-50%);
  display: ${(props) => props.isToggle ? 'none' : ''};

}

&:after {
  width: 100%;            
  height:  0.2rem;          
  top: 50%;              
  left: 0;
  transform: translateY(-50%); 

}

`;
export const ToggleBtn = styled.button`
  padding: 1rem 0.6rem;
  text-align: center;
  border-radius: 0.2rem;
  display: block;
 
  ${({ theme: { JobSearch }, }) =>
    JobSearch &&
  css`
    background: ${JobSearch.BgColors.toogleBtn};
    border: 0.1rem solid ${JobSearch.BdrColors.toogleBtn};
    &:hover ${ToggleIcon}{
    &:before,
    &:after{
    background-color: ${JobSearch.BgColors.toogleBtnHover};
    }
  }
    `
  }
  
  

`
export const ExapndJobList = styled.div`
${({ theme: { JobSearch }, }) =>
    JobSearch &&
  css`
    border-top: 0.1rem solid ${JobSearch.BdrColor.expandJobList};
    `
}
text-align: right;

`
export const JobsLinkList = styled.div`

  overflow-y: hidden;

ul{
  ${device.mobileLg}{
  max-height: 9.1rem;
  min-width: 85rem;
}
}

`
