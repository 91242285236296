import { ArrowIcon } from '@components/jobsSection/styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const ButtonLg = styled.a<{ isRCPortal?: boolean }>`
  display: block;
  ${({ theme: { JobAction }, isRCPortal }) =>
    JobAction &&
    css`
      background-color: ${JobAction?.BgColors?.btnLgWrapper};
      border: 0.1rem solid ${JobAction?.BdrColors?.btnLgWrapper};
      padding: 0.8rem 1.6rem;
      font-size: ${isRCPortal ? '1.6rem' : '1.75rem'};
      line-height: 1.5;
      color: ${JobAction?.Colors?.btnLgWrapper};
      font-family: ${JobAction?.FontFamily?.btnLgWrapper};
      text-transform: ${isRCPortal ? null : 'uppercase'};
      font-weight: ${isRCPortal ? '600' : null};
      text-align: center;
      ${isRCPortal
        ? css`
            border-radius: 0.4rem;
            max-width: 20rem;
            margin: auto;
          `
        : null};

      &:hover {
        color: ${JobAction?.Colors?.btnLgWrapperHover};
        background-color: ${JobAction?.BgColors?.btnLgWrapperHover};
        border-color: ${JobAction?.BdrColors?.btnLgWrapperHover};
        text-decoration: none;
      }
    `}
`;
//
export const TitleWrap = styled.a<{
  isHeading?: boolean;
  isRCPortal?: boolean;
}>`
  cursor: pointer;
  padding: 0.16rem 0;
  font-size: 1.4rem;
  ${({ isHeading, theme: { JobAction } }) =>
    isHeading &&
    css`
      font-size:${JobAction.FontSizes.titleWrapText};
      color: ${JobAction?.Colors?.titleWrapText};
      padding: 0 0 1.1rem ;
      font-family: ${JobAction.FontFamily.titleWrap};
      text-transform: uppercase;
      ${Mixin.AllCenter({ justify: 'space-between' })};
      &:only-child {
        margin: 0;
      }
     
    `}
  ${({ theme: { JobAction } }) =>
    JobAction &&
    css`
      &:hover {
        text-decoration: none;
        color: ${JobAction.Colors.titleWrap};
        ${ArrowIcon}:after {
          border-color: ${JobAction.BdrColors.titleWrap};
        }
      }
      &:focus{
        outline-offset: 0.2rem;
      }
    `}
`;
export const ContentWrapper = styled.ul<{
  subList?: boolean;
  isRCPortal?: boolean;
}>`
  list-style: none;
  padding: 0;
  margin: ${(props) => (props.subList ? '0 0 0.8rem' : '0')};
  ${({ isRCPortal }) =>
    isRCPortal &&
    css`
      > .action-item {
        margin-bottom: 1.1rem;

        .action-link {
          padding: 0;
          margin-bottom: 1.1rem;
          width: 100%;
          font-size: 1.6rem;
          &:focus {
            outline-offset: 0.2rem;
          }
        }
      }
    `}
`;
export const JobsToggleBtn = styled.span`
  height: 1.4rem;
  width: 1.4rem;
  ${({ theme: { JobAction } }) =>
    JobAction &&
    css`
      background-color: ${JobAction.BgColors.jobsToggleBtn};
    `}
  border-radius: 50%;
  padding: 0;
  ${Mixin.AllCenter()}
`;
export const LinkList = styled.ul<{ isRCPortal?: boolean }>`
  padding: 1.5rem 0;
  margin: 1.5rem 0 0;
  ${({ theme: { JobAction }, isRCPortal }) =>
    JobAction &&
    css`
      border-top: 0.1rem solid ${JobAction.BdrColors.linkListWrapper};
      list-style: none;
      display: flex;
      align-items: center;
      > li {
        margin: 0 0.4rem;
        a {
          color: ${JobAction.BdrColors.linkList};
          font-size: 2.1rem;
          border: 0.13rem solid ${JobAction.BdrColors.linkListContent};
          border-radius: 0.21rem;
          display: block;
          width: 3.45rem;
          text-align: center;
          ${isRCPortal &&
      css`
            &:hover {
              text-decoration: none;
            }
          `}
        }
      }
    `}
`;
export const FormMessage = styled.p<{ isCheckBoxHead?: boolean }>`
  font-weight: 700;
  margin: ${(props) =>
    props.isCheckBoxHead ? '2.4rem 0 1.6rem' : '0 0 1.6rem'};
`;
export const FormWrapper = styled.form`
  margin-bottom: 2rem;
  .btn-submit {
    min-width: 11.25rem;
  }
`;

export const CheckBoxWrap = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1.6rem 0 0.75rem;
  li {
    padding: 0.3rem 0;
    input {
      accent-color: initial;
    }
  }
`;
