import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const JobsContentWrap = styled.div<{ isMobileView?: boolean }>`
  ${({ isMobileView }) =>
    Mixin.GridLayout({
      gridDimension: isMobileView ? '100%' : '1fr 29.8rem',
      columnGap: isMobileView ? '0' : '1.5rem',
    })}
`;
export const ContentBox = styled.div<{
  isSecondaryBox?: boolean;
  isSecondarySm?: boolean;
  isRCPortal?: boolean;
  isCompanyComponent?: boolean;
}>`
  ${({ theme: { JobSection }, isCompanyComponent }) =>
    JobSection &&
    css`
      background-color: ${JobSection.BgColors.contentBoxWrapper};
      border: 0.1rem solid ${JobSection.BdrColors.contentBoxWrapper};
      ${isCompanyComponent &&
      css`
        margin-top: 7.5rem;
      `}
    `}
  padding:${(props) =>
    props.isSecondaryBox
      ? '2rem 2rem 0.8rem'
      : props.isSecondarySm
      ? '1.6rem'
      : '2rem'};
  margin-bottom: 1.6rem;
`;
export const JobsHeading = styled.h2`
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.2;
  ${({ theme: { JobSection } }) =>
    JobSection &&
    css`
      color: ${JobSection.Colors.jobsHeading};
      font-family: ${JobSection.FontFamily.titleWrap};
      display: flex;
      align-items: center;
      text-transform: uppercase;
      margin: 0 0 1.6rem;
      a {
        color: ${JobSection.Colors.jobsHeading};
        &:hover {
          text-decoration: none;
          color: inherit;
        }
      }
      &:after {
        content: '';
        height: 0.3rem;
        flex-grow: 1;
        background-color: ${JobSection.BgColors.jobsHeading};
        margin-left: 0.8rem;
      }
    `}
  ${device.mobileLg} {
    font-size: 2.338rem;
  }
`;
export const JobListWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  ${({ theme: { JobSection } }) =>
    JobSection &&
    css`
      border: 0.1rem solid ${JobSection.BdrColors.jobListWrapper};
      background-color: ${JobSection.BgColors.jobListWrapper};
    `}
`;
export const JobList = styled.li`
  padding: 1.6rem;
  ${({ theme: { JobSection } }) =>
    JobSection &&
    css`
      border-bottom: 0.1rem solid ${JobSection.BdrColors.jobList};
    `}
  position: relative;
  &:not(:last-child) {
    ${Mixin.GridLayout({
      gridDimension: '4.5rem 1fr max-content',
      columnGap: '1.6rem',
      mobDimension: '1fr 7rem',
    })};
    align-items: center;
    ${device.mobileTab} {
      grid-template-columns: 1fr 7rem;
    }
  }
  &:last-child {
    margin: 0;
    text-align: right;
  }
  p {
    margin: 0;
  }
  &:hover:not(:last-child) {
    background-color: #fcfcfc;
  }
`;
export const JobLink = styled.a`
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: capitalize;
  &:hover {
    text-decoration: none;
  }
  &::before {
    content: '';
    ${Mixin.Position({ position: 'absolute', value: '0 0 0 0' })}
  }
`;
export const TimeWrap = styled.span`
  font-size: 1.12rem;
`;
export const ArrowIcon = styled.span<{ BtnArrow?: boolean;isRCPortal?:boolean;isRemoteJobsPage?:boolean;isToggle?:boolean }>`
  &::after {
    content: '';
    display: inline-block;
    border-width: ${({isRCPortal})=>isRCPortal ? '0.2rem 0.2rem 0 0' : '0.25rem 0.25rem 0 0'};
    ${({ theme: { JobSection }, BtnArrow}) =>
      JobSection &&
      css`
        border-color: ${BtnArrow
          ? JobSection.BdrColors.arrowIconIsTrue
          : JobSection.BdrColors.arrowIconIsFalse};
      `}

    border-style: solid;
    height: 0.7rem;
    width: 0.7rem;
    transform: ${({isRCPortal,isToggle})=>isRCPortal && isToggle ? 'rotate(135deg)' : 'rotate(45deg)'};
    margin-left: 0.3rem;
  }
`;
export const SeeAllLink = styled.a`
  font-size: 1.6rem;
  font-weight: 400;

  ${({ theme: { JobSection } }) =>
    JobSection &&
    css`
      font-family: ${JobSection.FontFamily.titleWrap};
      &:hover {
        text-decoration: none;

        &::after {
          border-color: ${JobSection.BdrColors.seeAllLink};
        }
      }
    `}
`;
export const ImgWrapper = styled.span`
  img {
    max-height: 4.6rem;
  }
`;
export const Badge = styled.span`
  font-size: 0.84rem;
  ${({ theme: { JobSection } }) =>
    JobSection &&
    css`
      color: ${JobSection.Colors.badgeWrapper};
      background-color: ${JobSection.BgColors.badgeWrapper};
    `}

  padding: 0.26rem 0.42rem;
  display: inline-block;
`;

export const JobDataList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;
export const JobListItem = styled.li`
  display: inline-block;
  vertical-align: top;
  margin-right: 0.4rem;
`;
export const JobActionsWrapper = styled.div`
  margin-left: 1.6rem;
`;
